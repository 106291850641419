const globalNav = document.querySelector("#navigation");
const btnNavOpen = document.querySelector("#nav_open");
const btnNavClose = document.querySelector("#nav_close");
const btnLink = document.querySelectorAll("#navigation a");

btnNavOpen.addEventListener("click", () => {
    if(!globalNav.classList.contains("open")){
        globalNav.classList.add("open");
    }
})

btnNavClose.addEventListener("click", () => {
    if(globalNav.classList.contains("open")){
        globalNav.classList.remove("open");
    }
})

btnLink.forEach(item => {
    item.addEventListener("click", () => {
        if(globalNav.classList.contains("open")){
            globalNav.classList.remove("open");
        }
    })
})

